<template>
    <div>
        <NavBar />
        <div class="container">
            <div class="row">
                <div class="col-sm-6">
                    <v-btn v-if="this.$store.getters['user/getUserRole'] == 'admin'" @click="redirectToAdd"
                        class="btn btn-primary success">
                        Προσθηκη
                    </v-btn>
                </div>
            </div>
            <div class="col-sm-12">
                <v-data-table :headers="headers" :loading="loading" :items="items" item-key="id"
                    :server-items-length="totalItems" @pagination="onPagination"
                    :footer-props="{ itemsPerPageOptions: [5, 10, 20, 50, 100] }">
                    <template v-slot:item.actionView="{ item }">
                        <v-btn color="success" small @click="setSelectedInvitation(item)">Αιτηση</v-btn>
                    </template>
                    <template v-slot:item.actionEdit="{ item }">
                        <v-btn v-if="userRole == 'admin'" color="info" small
                            @click="edit(item)">Επεξεργασια</v-btn>
                    </template>
                </v-data-table>
            </div>
        </div>
    </div>
</template>

<script>
import NavBar from "./NavBar.vue";
import axios from "axios";
import Swal from "sweetalert2";
import { mapActions, mapGetters } from "vuex";
import router from "../router";
import store from "../state/store";

export default {
    name: "InvUserList",
    components: { NavBar },

    data() {
        return {
            searchQuery: "",
            personSearch: [],
            items: [],
            totalPages: 0,
            rowsPerPageItems: [10, 20, 50],
            filteredPersons1: [],
            displayedItems: [],
            currentPage: 1,
            itemsPerPage: 10,
            perPage: 10,
            pagination: {
                page: 1,
                rowsPerPage: 10,
            },
            search: "",
            showAddForm: false,
            showEditForm: false,
            selectedItem: null,
            firstName: "",
            lastName: "",
            fatherName: "",
            loading: true,
            persons: [],
            selectedCategory: null,
            categoryName: "",
            editor: "",
            bio: "",
            totalItems: 0,
            origin: "",
            property: "",
            bibliography: "",
            searchItems: {
                firstName: "",
                lastName: "",
            },
            headers: [
                { text: "Όνομα Πρόσκλησης", value: "invName" },
                { text: "Έναρξη Πρόσκλησης", value: "fromDate" },
                { text: "Λήξη Πρόσκλησης", value: "toDate" },
                { text: "Δράσεις", value: "actionView", sortable: false },
                { text: "", value: "actionEdit", sortable: false },
            ],
        };
    },

    computed: {
        userRole() {
            return this.$store.getters['user/getUserRole'];
        }
    },

    mounted() {
        axios.defaults.headers.common["Authorization"] = "Bearer " + store.state.user.token;
    },

    methods: {
        ...mapActions("person", ["showDetails", "editDetails", "editDetailsInv"]),
        ...mapGetters("user", ["getUserRole"]),
        ...mapGetters("invitation", ["getUserRole"]),
        async onPagination({ page, itemsPerPage = 10 }) {
            this.loading = true;
            console.log(page, itemsPerPage, this.searchQuery);
            if (this.searchQuery) {
                this.searchPersons(page, itemsPerPage);
            } else {
                this.getInvitationsPaged(page, itemsPerPage)
            }
        },

        async setSelectedInvitation(item) {
            await this.$store.dispatch("invitation/setSelectedInvitationAction", item).then(() => {
                router.push("/MySubmission");
            }).catch((error) => {
                console.error(error);
            });
        },

        async searchPersons(page, itemsPerPage) {
            try {
                axios.defaults.headers.common["Authorization"] =
                    "Bearer " + store.state.user.token;
                const response = await axios.get(
                    axios.defaults.baseURL +
                    `/invitation/invitation/search?query=${this.searchQuery}&page=${page - 1}&pageSize=${itemsPerPage}`
                );
                this.totalPages = response.data.totalPages;
                this.totalItems = response.data.totalElements;
                this.items = response.data.content;
                this.loading = false;
                console.log(this.totalItems);
            } catch (error) {
                console.log(error);
            }
        },

        async getInvitationsPaged(page, itemsPerPage) {
            axios.defaults.headers.common["Authorization"] =
                "Bearer " + store.state.user.token;
            axios
                .get(
                    axios.defaults.baseURL +
                    `/invitation/userInvitationPaged?page=${page - 1}&size=${itemsPerPage}`
                )
                .then((response) => {
                    console.log(response);
                    this.items = response.data.content;
                    this.totalPages = response.data.totalPages;
                    this.totalItems = response.data.totalElements;
                    this.loading = false;
                })
                .catch((error) => {
                    console.error(error);
                });
        },

        show(item) {
            this.showDetails(item);
        },

        edit(item) {
            this.editDetailsInv(item);
        },

        redirectToAdd() {
            this.$router.push("/addInv");
        },
        async Delete(item) {
            const confirmed = await Swal.fire({
                title: `Είσαι σίγουρος ότι θέλεις να διαγράψεις: ${item.invName}?`,
                icon: "warning",
                showCancelButton: true,
                confirmButtonText: "Ναί, διαγραφή!",
                cancelButtonText: "Όχι",
                reverseButtons: false,
            });
            if (confirmed.isConfirmed) {
                axios
                    .post(axios.defaults.baseURL + "/invitation/deleteInvitation", item)
                    .then(async (response) => {
                        console.log(response.data);
                        if (response.data === true) {
                            await Swal.fire({
                                icon: "success",
                                title: "Επιτυχής Διαγραφή",
                                text: "Η πρόσκληση διαγράφτηκε επιτυχώς.",
                                timer: 2000,
                                timerProgressBar: true,
                                allowOutsideClick: false,
                                allowEscapeKey: false,
                                allowEnterKey: false,
                                showConfirmButton: false
                            });
                            this.getInvitationsPaged(this.pagination.page, this.itemsPerPage);
                        } else {
                            await Swal.fire({
                                icon: "error",
                                title: "Ανεπιτυχής Διαγραφή",
                                text: "Η πρόσκληση διαγράφτηκε ανεπιτυχώς.",
                                timer: 2000,
                                timerProgressBar: true,
                                allowOutsideClick: false,
                                allowEscapeKey: false,
                                allowEnterKey: false,
                                showConfirmButton: false
                            });
                        }
                    })
                    .catch((error) => {
                        console.error(error);
                        alert("Failed to fetch categories");
                    });
            }
        },
    },
};
</script>

<style scoped>
footer {
    position: fixed;
    left: 0;
    bottom: 0;
    width: 100%;
    height: 5%;
    background-color: #000000 !important;
    color: #ffffff;
    text-align: right !important;
}

.container {
    max-width: 1000px;
    margin: 0 auto;
    padding: 20px;
}

.v-data-table__wrapper {
    overflow-x: auto;
}

.v-data-table__wrapper table {
    width: 100%;
    white-space: nowrap;
}

.v-data-table__wrapper td {
    padding: 16px;
    text-overflow: ellipsis;
    vertical-align: middle;
}

@media (max-width: 767px) {
    .container {
        padding: 10px;
    }

    .v-data-table__wrapper th {
        font-size: 12px;
        font-weight: 500;
        white-space: nowrap;
    }

    .v-data-table__wrapper td {
        font-size: 14px;
        white-space: nowrap;
    }
}
</style>