/* eslint-disable no-unused-vars */
import router from "@/router/index.js";
import Vue from "vue";
import VueRouter from "vue-router";
import axios from 'axios';

Vue.use(VueRouter);

export default {
    namespaced: true,
    state: {
        id: null,
        allPersons: [],
    },

    mutations: {
        setTotalElements(state, totalElements){            
            state.pagination.totalElements = totalElements
        },

        setPersons(state, allPersons) {
            state.allPersons = allPersons;
        },

        setSelectedItemId(state, id) {
            state.id = id;
        },
        setSelectedInvId(state, inv) {
            state.selectedInv = inv;
        },
    },

    actions: {

        fetchAllPersonsAction: ({ commit }) => {
            return new Promise((resolve, reject) => {
                axios
                    .get("/api/persons")

                    .then(({ data, status }) => {
                        if (status === 200) {
                            commit("setPersons", data.content);
                            resolve(true);
                        }
                    })
                    .catch((error) => {
                        reject(error);
                    });
            });
        },

        fetchAllPersonsPagedAction: ({ commit },pagination) => {
            return new Promise((resolve, reject) => {                
                axios
                    .get(`/api/personsPaged?page=${pagination.offset}&size=${pagination.rowsPerPage}`)
                    .then(({ data, status }) => {
                        if (status === 200) {
                            commit("setPersons", data.content);
                            // commit("setTotalElements", data.totalElements);
                            resolve(true);
                        }
                    })
                    .catch((error) => {
                        reject(error);
                    });
            });
        },
       

        searchPersonsByLastOrFirstNameAction: ({ commit }, searchName) => {
            return new Promise((resolve, reject) => {
                axios
                    .get("/api/persons/search/" + searchName)
                    .then(({ data, status }) => {
                        if (status === 200) {
                            commit("setPersons", data);
                            resolve(true);
                        }
                    })
                    .catch((error) => {
                        reject(error);
                    });
            });
        },

        showDetails({ commit }, item) {
            commit('setSelectedItemId', item.id);
            router.push({ name: 'view', params: { id: item.id } });
        },

        editDetails({ commit }, item) {
            commit('setSelectedItemId', item.id);
            router.push({ name: 'edit', params: { id: item.id} });
        }
        ,
        showDetailsInv({ commit }, item) {
            commit('setSelectedInvId', item);
            router.push({ name: 'InvEditView', params: { id: item.id,status:"show" } });
        },

        editDetailsInv({ commit }, item) {
            commit('setSelectedInvId', item);
            router.push({ name: 'EditInvitation', params: { id: item.id,status:"edit" } });
        }
    },

    getters: {
        getSelectedItemId(state) {
            return state.id;
        },

        getAllPersons: state => state.allPersons,
    },
};
