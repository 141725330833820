<template>
  <div class="background-image">
    <div v-if="isFetching" class="LoadingContainer"><v-progress-circular indeterminate :size="36"></v-progress-circular>
    </div>
    <NavBar />

    <v-form @submit.prevent="update" class="mt-5" ref="form" v-model="valid">
      <h1
        style="font-weight: 600;font-family:'Gill Sans', 'Gill Sans MT', Calibri, 'Trebuchet MS', sans-serif; text-align: center;padding-bottom: 20px;">
        Προσωπικες Πληροφορίες Xρήστη: </h1>
      <v-row justify="center" align="center">
        <v-col cols="2" class="hide-on-small"></v-col>
        <v-col cols="12" sm="4" md="4" class="show-on-small" style="padding-top: 40px;">
          <v-text-field v-model="user.userName" readonly prepend-icon="mdi-account" :label="$t('usernameRegister')"
            outlined dense></v-text-field>
        </v-col>
        <v-col cols="12" sm="4" md="4" class="show-on-small" style="padding-top: 40px;">
          <v-text-field v-model="user.userFirstName" readonly start-append prepend-icon="mdi-account" color="#00695C"
            :label="$t('firstname')" outlined dense></v-text-field>
        </v-col>
        <v-col cols="2" class="hide-on-small"></v-col>
      </v-row>


      <v-row justify="center" align="center">
        <v-col cols="2" class="hide-on-small"></v-col>
        <v-col cols="12" sm="2" md="4" class="show-on-small">
          <v-text-field v-model="user.userLastName" readonly start-append prepend-icon="mdi-account" color="#00695C"
            label="Επίθετο" outlined dense></v-text-field>
        </v-col>
        <v-col cols="12" sm="2" md="4" class="show-on-small">
          <v-text-field v-model="user.userEmail" readonly start-append prepend-icon="mdi-email" name="Password"
            label="Email" type="email" autocomplete="on" outlined dense></v-text-field>
        </v-col>
        <v-col cols="2" class="hide-on-small"></v-col>
      </v-row>

      <v-row justify="center" align="center">
        <v-col cols="2" class="hide-on-small"></v-col>
        <v-col cols="12" sm="4" md="4" class="show-on-small">
          <v-text-field v-model="user.birthdate" type="date" prepend-icon="mdi-calendar-range" name="birthDate"
            label="Ημερομηνία Γέννησης" outlined dense></v-text-field>
        </v-col>
        <v-col cols="12" sm="4" md="4" class="show-on-small">
          <v-select v-model="user.gender" prepend-icon="mdi-gender-male-female" label="Επιλογή φύλου"
            :items="['Male', 'Female']" outlined dense></v-select>
        </v-col>
        <v-col cols="2" class="hide-on-small"></v-col>
      </v-row>

      <v-row justify="center" align="center">
        <v-col cols="2" class="hide-on-small"></v-col>
        <v-col cols="12" sm="4" md="4" class="show-on-small" style="padding-top: 40px;">
          <div class="fieldsTitle">Πληροφορίες Τοποθεσίας</div>

          <!-- <v-text-field v-model="user.userState" start-append prepend-icon="mdi-earth" color="#00695C" label="Χώρα"
            outlined dense></v-text-field> -->

          <v-select v-model="user.userState" :items="countries" start-append prepend-icon="mdi-earth" color="#00695C"
            label="Χώρα" outlined dense></v-select>


        </v-col>
        <v-col cols="12" sm="4" md="4" class="show-on-small" style="padding-top: 40px;">
          <v-text-field v-model="user.citizenship" start-append prepend-icon="mdi-earth" color="#00695C"
            label="Υπηκοότητα" outlined dense></v-text-field>
        </v-col>
        <v-col cols="2" class="hide-on-small"></v-col>
      </v-row>

      <v-row justify="center" align="center">
        <v-col cols="2" class="hide-on-small"></v-col>
        <v-col cols="12" sm="4" md="4" class="show-on-small">
          <v-text-field v-model="user.prefecture" start-append prepend-icon="mdi-information" color="#00695C"
            label="Νομός" outlined dense></v-text-field>
        </v-col>
        <v-col cols="12" sm="4" md="4" class="show-on-small">
          <v-text-field v-model="user.userCity" start-append prepend-icon="mdi-city" color="#00695C" :label="$t('City')"
            outlined dense></v-text-field>
        </v-col>
        <v-col cols="2" class="hide-on-small"></v-col>
      </v-row>

      <v-row justify="center" align="center">
        <v-col cols="2" class="hide-on-small"></v-col>
        <v-col cols="12" sm="4" md="4" class="show-on-small">
          <v-text-field v-model="user.userAddress" start-append prepend-icon="mdi-earth" color="#00695C" label="Οδός"
            outlined dense></v-text-field>
        </v-col>
        <v-col cols="12" sm="4" md="4" class="show-on-small">
          <v-text-field v-model="user.userZipCode" start-append prepend-icon="mdi-account" color="#00695C"
            :label="$t('ZipCode')" outlined dense></v-text-field>
        </v-col>
        <v-col cols="2" class="hide-on-small"></v-col>
      </v-row>

      <!-- <div class="fieldsTitle">Πληροφοριες Επικοινωνίας</div> -->
      <v-row justify="center" align="center">
        <v-col cols="2" class="hide-on-small"></v-col>
        <v-col cols="12" sm="4" md="4" class="show-on-small" style="padding-top: 40px;">
          <div class="fieldsTitle">Πληροφορίες Επικοινωνίας</div>
          <v-text-field v-model="user.mobilePhone" start-append prepend-icon="mdi-cellphone" color="#00695C"
            :label="$t('MobilePhone') + '*(required)'" :rules="mobilePhoneRules" outlined dense></v-text-field>
        </v-col>
        <v-col cols="12" sm="4" md="4" class="show-on-small" style="padding-top: 40px;">
          <v-text-field v-model="user.homePhone" start-append prepend-icon="mdi-phone" color="#00695C"
            label="Τηλέφωνο Οικίας" outlined dense></v-text-field>
        </v-col>
        <v-col cols="2" class="hide-on-small"></v-col>
      </v-row>


      <div v-if="!isloading"
        style="width: 100%; display: flex; justify-content: center; align-items: center;margin-left: 6px; gap: 20px;">
        <v-btn :loading="loadingLogin" style="min-width:fit-content; width: 170px; color: white; margin-bottom: 100px;"
          @click="goBack" color="blue" large class="margin rounded-pill">Πισω</v-btn>
        <v-btn :loading="loadingLogin" style="min-width:fit-content; width: 170px; color: white; margin-bottom: 100px;"
          type="submit" :disabled="!valid" color="blue" large class="margin rounded-pill">Αποθηκευση</v-btn>

      </div>
      <div v-else class="text-center"
        style="display: flex;justify-content: center;align-items: center;margin-left: 6px; color: white; margin-bottom: 100px;">
        <v-progress-circular color="blue" indeterminate :size="40"></v-progress-circular>
      </div>
    </v-form>
  </div>
</template>

<script>


import NavBar from "./NavBar.vue";
import axios from "axios";
import router from "../router";
import store from "../state/store";
import { mapActions } from "vuex";
import countriesData from "../assets/countries"

export default {
  name: "Signup",
  components: {

    NavBar,
  },

  data: () => ({
    user: {},
    valid: false,
    mobilePhoneRules: [
      v => (v && v.length === 10) || 'Το κινητό πρέπει να αποτελείται από 10 ψηφία'
    ],
    positionRules: [
      v => (v && v.length >= 1) || 'Πρέπει να επιλέξετε τουλάχιστον μία θέση'
    ],
    loadingLogin: false,
    showPassword: false,
    showRePassword: false,
    isloading: false,
    isFetching: false,
    jobRole: null,
    countries: countriesData
  }),
  mounted() {
    axios.defaults.headers.common["Authorization"] =
      "Bearer " + store.state.user.token;
    this.user = this.$store.state.user.userObj;
    this.underFile = null;
  },
  methods: {

    ...mapActions("user", ["updateUserAction"]),

    goBack() {
      router.push('/InvActiveList');
    },
    testMethod() {
      console.log(this.user.underFile.size);
    },
    async update() {
      this.updateUserAction(this.user);
    },
  },
};
</script>

<style scoped>
.LoadingContainer {
  z-index: 1000;
  position: fixed;
  left: 0;
  top: 0;
  width: 100%;
  height: 100%;
  background-color: rgba(225, 225, 225, 0.442);
  display: flex;
  justify-content: center;
  align-items: center;
}

.v-card__actions {
  justify-content: space-between;
  align-items: center;
}

.fileButtonDownload {
  color: rgb(0, 153, 255);
  cursor: pointer;
  /* z-index: 100; */
  padding-bottom: 10px;
  padding-left: 5px;
  z-index: 100;
}

.fieldsTitle {
  position: absolute;
  font-weight: 600;
  font-size: 20px;
  margin-top: -40px;
  margin-left: 32px;
  text-wrap: nowrap;
}

.fileButtonDownload:hover {
  text-decoration: underline;
}

.form {
  display: flex;
  flex-direction: column;
  align-items: center;
}

footer {
  position: fixed;
  left: 0;
  bottom: 0;
  width: 100%;
  height: 5%;
  background-color: #000000 !important;
  color: #ffffff;
  text-align: right !important;
}

@media screen and (max-width: 600px) {
  .margin {
    margin-bottom: 15vw !important;
  }

  .show-on-small {
    display: block;
  }

  .show-on-small .v-input {
    width: 100%;
  }

  .hide-on-small {
    display: none;
  }
}

/* Media query for mobile screens (up to 4120px) */
@media screen and (max-width: 4120px) {
  .margin {
    margin-bottom: 15vw !important;
  }
}

.text-input {
  width: 100%;
  margin-bottom: 20px;
}
</style>
